import React from 'react'
import LogoColumn from '../Home/LogoColumn'
import Navbar from '../Utility/Navbar'

function Stories() {
  return (
    <div>
<LogoColumn />
{/* <Navbar /> */}<br/><br/><br/>
<h3>Share your story</h3>

    </div>
  )
}

export default Stories