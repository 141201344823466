import React from 'react';
// import Topbar from "./Home/Topbar";
import Router from "./Utility/Router";
// import Subscriptiontag from './Subscription/Subscriptiontag';

function App() {
  return (
    <>
      {/* <Topbar/> */}
      {/* <Subscriptiontag/> */}
      <Router />
    </>
  )
}

export default App
