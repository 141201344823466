import React from "react";
import SubscriptionDoor from "../Subscription/SubscriptionDoor";

function SubscriptionPage(){
    return(
        <>       
       <SubscriptionDoor/>
        </>
    )
}
export default SubscriptionPage;