import React from "react";
import I12 from '../../images/posts/Hippie_Culture.jpg'; 
import I11 from '../../images/posts/minimalistic_living.jpg'; 
import I10 from '../../images/posts/Tackling_financial_problems.jpg'; 
import I13 from '../../images/posts/optical_illusions.jpg'; 
import I15 from '../../images/posts/Burning_Library.jpeg'; 
import I8 from '../../images/posts/Fashion_Transformed.jpg'; 
import I7 from '../../images/posts/Travel_with_Family.jpg';
import I5 from '../../images/posts/The_little_Prince.jpg';
import I4 from '../../images/posts/Suitable_Guide_for_career_planning.jpg';
import I3 from '../../images/posts/PENNY_1.jpg';
import I2 from '../../images/posts/Kids_vs_Technology.jpeg';
import I1 from '../../images/posts/KATHAL.jpg';


function Instagram() {
    return (
        <>
            <div className="col-xs-12 col-md-3 col-sm-6 ">
                <h3>Instagram</h3>
                <div className="insta-gallery">
                    {/* <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I1} /> </a>
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I2} /> </a>
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I3} /> </a>*/}
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I4} /> </a> 
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I5} /> </a>
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I7} /> </a>
                    
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I11} /> </a>
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I12} /> </a>
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I13} /> </a>
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I10} /> </a>
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I15} /> </a>
                    <a href="https://www.instagram.com/aslikahani/" className="tt-lightbox"> <img className="img-responsive center-block" alt="aslikahaniimage" src={I8} /> </a>

                </div>
            </div>
        </>
    );
}
export default Instagram;